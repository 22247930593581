var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_c('faq-list')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }