<template>
    <component :is="template">
        <faq-list />
    </component>
</template>
<script>
import FaqList from "@/components/client/center/faqs/faq-list.vue";
export default {
    components: {
        FaqList
    },
    computed: {
        template(){
            return () => import(`@/templates/${this.$theme.pages.center}`);
        }
    }
}
</script>