var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "faqs"
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.faqs, function (faq, index) {
    var _faq$answer, _faq$answer$replace;
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_c('v-flex', {
      staticClass: "mr-4",
      attrs: {
        "grow-shrink-0": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v(_vm._s(_vm.mdiAlphaQBoxOutline))])], 1), _c('v-flex', [_vm._v(_vm._s(faq.question))])], 1), _c('v-expansion-panel-content', {
      attrs: {
        "color": "#f9f9f9"
      }
    }, [_c('v-layout', {
      staticClass: "py-4"
    }, [_c('v-flex', {
      staticClass: "pr-4",
      attrs: {
        "grow-shrink-0": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "accent"
      }
    }, [_vm._v(_vm._s(_vm.mdiAlphaABoxOutline))])], 1), _c('v-flex', [_c('p', {
      domProps: {
        "innerHTML": _vm._s((_faq$answer = faq.answer) === null || _faq$answer === void 0 ? void 0 : (_faq$answer$replace = _faq$answer.replace) === null || _faq$answer$replace === void 0 ? void 0 : _faq$answer$replace.call(_faq$answer, /\n/g, '<br>'))
      }
    })])], 1)], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }