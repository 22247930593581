<template>
<div class="faqs">
    <v-expansion-panels accordion>
        <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
            <v-expansion-panel-header>
                <v-flex grow-shrink-0 class="mr-4"><v-icon color="primary">{{ mdiAlphaQBoxOutline }}</v-icon></v-flex>
                <v-flex>{{ faq.question }}</v-flex>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="#f9f9f9">
                <v-layout class="py-4">
                    <v-flex grow-shrink-0 class="pr-4"><v-icon color="accent">{{ mdiAlphaABoxOutline }}</v-icon></v-flex>
                    <v-flex><p v-html="faq.answer?.replace?.(/\n/g, '<br>')"></p></v-flex>
                </v-layout>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</div>
</template>

<script>
import api from "@/api";
import { mdiAlphaQBoxOutline, mdiAlphaABoxOutline } from "@mdi/js";

export default {
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            mdiAlphaQBoxOutline,
            mdiAlphaABoxOutline,

            filter: {
                type: "회원서비스",
            },
            faqs: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search(){
            try{
                var { summary, faqs } = await api.v1.center.faqs.gets({
                    params: this.filter
                });

                this.faqs = faqs;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    }
}
</script>

<style scoped>
.faqs >>> .v-expansion-panel--active>.v-expansion-panel-header {
    min-height: unset;
}
.faqs >>>.v-expansion-panel-content__wrap {
    padding-top: 0;
    padding-bottom: 0;
}
</style>